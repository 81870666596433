<template>
  <div class="home">
    <clientHeader />
    <div class="all-content" v-if="activePackage">
      <div class="intro">
        <div class="intro-content">
          <div class="intro-body">
            <h2
              data-aos="fade-up"
              data-aos-duration="1000"
              class="intro-header"
            >
              {{ activePackage.hotel_name }}
            </h2>
            <p
              class="txt-shad-sm intro-desc"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Home / Hotels / <strong>{{ activePackage.hotel_name }}</strong>
            </p>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="tour-details">
          <div class="left-tour-content">
            <div class="headers">
              <div class="left-headers">
                <h2>
                  {{ activePackage.hotel_name }}
                </h2>
                <p>
                  <i class="fas fa-map-marker-alfa-solid fa-layer-group"></i>
                  <span>Stars: </span>
                  <strong>{{ activePackage.hotel_stars }}</strong>
                </p>
              </div>
              <div class="rigth-headers">
                <label for="">Starts From</label>
                <p class="price" v-if="activePackage.price">
                  <strong>{{ $money(activePackage.price) }}</strong>
                </p>
              </div>
            </div>
            <div class="image-section">
              <div class="main-img">
                <img :src="`${$uploads(activePackage.hotel_image)}`" alt="" />
                <div class="other-imgs">
                  <carousel
                    :items-to-show="6.5"
                    :items-to-scroll="3.5"
                    :wrap-around="true"
                  >
                    <CouSlide
                      v-for="slide in activePackage.hotel_images"
                      :key="slide"
                    >
                      <img
                        :src="`${$uploads(slide)}`"
                        alt=""
                        @click="activePackage.image = slide"
                      />
                    </CouSlide>

                    <template #addons>
                      <navigation />
                    </template>
                  </carousel>
                </div>
              </div>
            </div>
            <div class="detail-item overview">
              <h3>Overview</h3>
              <p v-html="activePackage.hotel_description"></p>
            </div>
            <div class="detail-item facilities">
              <h3>Facilities</h3>
              <div class="included-excluded">
                <div class="included">
                  <ul>
                    <li
                      v-for="item in activePackage.hotel_facilities"
                      :key="item"
                    >
                      {{ item }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="detail-item facilities">
              <h3>Nearby Facilities</h3>
              <div class="included-excluded">
                <div class="included">
                  <ul>
                    <li
                      v-for="item in activePackage.hotel_nearby_facilities"
                      :key="item"
                    >
                      {{ item }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="detail-item map">
              <h3>Map</h3>
              <div class="map-section">
                <iframe
                  :src="`https://www.google.com/maps?q='${activePackage.hotel_address}'&output=embed`"
                ></iframe>
              </div>
            </div>
          </div>
          <div class="right-tour-content">
            <div
              class="right-content-item room-item"
              v-for="room in rooms"
              :key="room.room_id"
            >
              <div class="room-name">
                <h3>{{ room.room_name }}</h3>
              </div>
              <div class="price">
                <h3>Price</h3>
                <p>
                  <strong>{{ $money(room.room_price) }}</strong> / Night
                </p>
              </div>
              <div class="facilities">
                <h3>Amenities</h3>
                <ul>
                  <li v-for="item in room.amenities" :key="item">{{ item }}</li>
                </ul>
              </div>
              <div class="book">
                <button @click="reserveModel = true">Reserve Now</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <leftView
      :modelTitle="'Reserve Room'"
      @closeModel="reserveModel = false"
      v-if="reserveModel == true"
    >
      <div class="reserve-room">
        <div class="form">
          <div class="form-header">
            <h2>
              Fill the form to reserve a room, our team will contact you right
              away.
            </h2>
          </div>
          <div class="form-body">
            <form action="">
              <div class="form-group">
                <label for="name">Room</label>
                <a-select
                  placeholder="Select Room"
                  style="width: 100%"
                  v-model:value="payload.room_id"
                >
                  <a-select-option
                    v-for="room in rooms"
                    :key="room.room_id"
                    :value="room.room_id"
                    >{{ room.room_name }} - {{ $money(room.room_price) }}
                  </a-select-option>
                </a-select>
              </div>
              <div class="form-group group-3">
                <div class="input-group">
                  <label for="check-in">Check-in</label>
                  <a-date-picker
                    v-model:value="payload.checkin_date"
                    @change="countDays()"
                    :format="dateFormatList"
                    placeholder="Checkin date"
                    :bordered="true"
                  />
                </div>
                <div class="input-group">
                  <label for="check-out">Check-out</label>
                  <a-date-picker
                    v-model:value="payload.checkout_date"
                    @change="countDays()"
                    :format="dateFormatList"
                    placeholder="Checkout date"
                  />
                </div>
                <div class="input-group">
                  <label for="check-out">Nights</label>
                  <a-input v-model:value="payload.days" />
                </div>
              </div>
              <div class="form-group">
                <label for="name">Full Name</label>
                <a-input
                  v-model:value="payload.full_name"
                  placeholder="Enter your full name"
                />
              </div>
              <div class="form-group">
                <label for="email">Email</label>
                <a-input
                  v-model:value="payload.email"
                  placeholder="Enter your email"
                />
              </div>
              <div class="form-group">
                <label for="phone">Phone Number</label>
                <a-input
                  v-model:value="payload.phone"
                  placeholder="Enter your phone number"
                />
              </div>
              <div class="form-group">
                <label for="message">Message</label>
                <a-textarea
                  v-model:value="payload.message"
                  placeholder="Any special request?"
                />
              </div>
              <div class="form-group">
                <button class="btn" type="submit" @click.prevent="sendOrder()">
                  Reserve Room
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </leftView>
    <clientFooter />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      color: "#000",
      packages: [],
      trips: [],
      partners: [],
      currentSlide: 0,
      activetab: 1,
      activePackage: {},
      activeId: null,
      reserveModel: false,
      rooms: [],
      selectedRoom: null,
      payload: {
        checkin_date: "",
        checkout_date: "",
        days: 0,
        room_id: null,
        hotel_id: "",
        full_name: "",
        email: "",
        phone: "",
        message: "",
        type: "Hotel",
      },
    };
  },
  methods: {
    prev() {
      if (this.currentSlide > 0) {
        this.currentSlide--;
      } else {
        this.currentSlide = this.slides.length - 1;
      }
    },
    next() {
      // if (this.currentSlide < this.slides.length - 1) {
      this.currentSlide++;
      // } else {
      //   this.currentSlide = 0;
      // }
    },
    getItems() {
      this.$startLoader();
      this.payload.hotel_id = this.activeId;
      this.$store
        .dispatch("GET_HOTEL_BY_ID", { hotel_id: this.activeId })
        .then((response) => {
          this.activePackage = response.data;
          this.rooms = response.data.rooms;
          this.$stopLoader();
        });
    },
    sendOrder() {
      this.$startLoader();
      this.$store
        .dispatch("SEND_HOTEL_ORDER", { ...this.payload })
        .then((response) => {
          if (response.data.status == "ok") {
            this.$notify({
              group: "status",
              type: "success",
              title: "Success",
              text: "Your order has been sent successfully, you will receive a confirmation email shortly",
            });
            this.$stopLoader();
            this.reserveModel = false;
          }
        })
        .catch((error) => {
          this.$notify({
            group: "status",
            type: "error",
            title: "Error",
            text: "An error occurred, please try again",
          });
          this.$stopLoader();
        });
    },

    countDays() {
      if (!this.payload.checkin_date || !this.payload.checkout_date) {
        return;
      }
      let date1 = new Date(this.payload.checkin_date);
      let date2 = new Date(this.payload.checkout_date);
      if (date1 > date2) {
        this.$notify({
          group: "status",
          type: "error",
          title: "Error",
          text: "Check-out date should be greater than check-in date",
        });
        this.payload.days = 0;
        this.payload.checkout_date = "";
        return;
      }
      let diffTime = Math.abs(date2 - date1);
      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1;
      this.payload.days = diffDays;
    },
    getTotals() {
      let room = this.rooms.find((item) => item.id == this.selectedRoom);
      let total = room.room_price * this.payload.days;
      console.log(total);
    },
  },
  mounted() {
    let vm = this;
    this.activeId = this.$route.params.id;
    this.getItems();
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/main.scss";
.home {
  width: 99.1vw;
  overflow: hidden;
  @media screen and (max-width: 900px) {
    width: 100vw;
  }
  .all-content {
    .tour-details {
      width: 100%;
      display: grid;
      grid-template-columns: 70% 30%;
      padding: 4rem 0;
      gap: 2rem;
      @media screen and (max-width: 900px) {
        grid-template-columns: 100%;
      }
      .left-tour-content {
        .headers {
          display: flex;
          justify-content: space-between;
          .left-headers {
            h2 {
              font-size: 1.8rem;
              color: #000;
              font-weight: 600;
            }
            p {
              font-size: 1rem;
              color: #000000dc;
              display: flex;
              align-items: center;
              gap: 0.2rem;
              font-weight: 300;
            }
          }
          .rigth-headers {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            label {
              font-size: 1rem;
              color: #000;
              font-weight: 400;
              text-align: right;
            }
            p {
              font-size: 1.5rem;
              color: $primary_color;
              font-weight: 500;
              text-align: right;
            }
          }
        }
        .icons {
          display: flex;
          justify-content: space-between;
          gap: 1rem;
          border-bottom: 1px #00000021 solid;
          border-top: 1px #00000021 solid;
          .icon-item {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem;
            border-radius: 5px;
            i {
              font-size: 1.75rem;
              color: #000;
            }
            .text-section {
              margin-left: 1rem;
              h4 {
                font-size: 1rem;
                color: #000;
                font-weight: 600;
              }
              p {
                font-size: 0.8rem;
                color: #00000096;
              }
            }
          }
        }
        .image-section {
          margin-top: 2rem;
          .main-img {
            position: relative;
            img {
              width: 100%;
              height: 400px;
              object-fit: cover;
              border-radius: 15px;
            }
            .other-imgs {
              width: 100%;
              img {
                height: 100px;
                object-fit: cover;
                border-radius: 10px;
                padding: 5px;
                cursor: pointer;
              }
            }
          }
        }
        .detail-item {
          margin-top: 2rem;
          padding: 20px;
          border-radius: 15px;
          box-shadow: 0 0 10px 0 #00000021;
          h3 {
            font-size: 1.5rem;
            color: #000;
            font-weight: 600;
            border-bottom: 1px $secondary_color solid;
            width: fit-content;
            line-height: 1.5;
          }
          ul {
            list-style: none;
            li {
              font-size: 1rem;
              color: #000;
              padding: 0.35rem 0;
              position: relative;
            }
          }
        }
        .overview {
          p ::v-deep p {
            line-height: 1.5;
          }
          p ::v-deep ul,
          ol {
            margin-left: 1rem;
          }
          ul {
            li {
              &:hover {
                &:before {
                  transform: scaleX(1);
                }
              }
            }
          }
        }
        .facilities {
          gap: 1rem;
          ul {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            @media screen and (max-width: 900px) {
              grid-template-columns: repeat(2, 1fr);
            }
            @media screen and (max-width: 600px) {
              grid-template-columns: 1fr;
            }
          }
        }
        .map {
          .map-section {
            iframe {
              width: 100%;
              height: 400px;
              border: none;
              border-radius: 15px;
            }
          }
        }
      }
      .right-tour-content {
        .right-content-item {
          margin-bottom: 2rem;
          border-radius: 15px;
          box-shadow: 0 0 10px 0 #00000021;
          .room-name {
            padding: 20px 20px;
            h3 {
              font-size: 1.5rem;
              color: #000;
              font-weight: 600;
              width: fit-content;
            }
          }
          .price {
            padding: 0 20px;
            h3 {
              font-size: 1.15rem;
              color: #000;
              font-weight: 600;
              border-bottom: 1px $secondary_color solid;
              width: fit-content;
              line-height: 1.5;
            }
            p {
              font-size: 0.9rem;
              color: #666;
              padding: 0.35rem 0;
              strong {
                font-size: 1.5rem;
                color: $primary_color;
                font-weight: 500;
              }
            }
          }
          .facilities {
            padding: 0 20px;
            h3 {
              font-size: 1.15rem;
              color: #000;
              font-weight: 600;
              border-bottom: 1px $secondary_color solid;
              width: fit-content;
              line-height: 1.5;
            }
            ul {
              list-style: none;
              li {
                font-size: 1rem;
                color: #666;
                padding: 0.35rem 0;
                position: relative;
                &:before {
                  content: "";
                  position: absolute;
                  width: 100%;
                  transform: scaleX(0);
                  height: 1px;
                  bottom: 0;
                  left: 0;
                  background-color: $primary_color;
                  transform-origin: bottom right;
                  transition: transform 0.4s ease-in-out;
                }
              }
            }
          }
          .book {
            margin-top: 1rem;
            display: flex;
            button {
              padding: 1rem 1rem;
              border: none;
              border-radius: 0px 0px 15px 15px;
              background-color: $primary_color;
              color: #fff;
              font-size: 1rem;
              font-weight: 600;
              cursor: pointer;
              transition: background-color 0.4s ease-in-out;
              width: 100%;
              &:hover {
                background-color: darken($primary_color, 10%);
              }
            }
          }
        }
      }
    }
  }
  .reserve-room {
    .form {
      width: 100%;
      .form-header {
        h2 {
          font-size: 1rem;
          color: #666;
          font-weight: 400;
          text-align: center;
          margin-top: 20px;
        }
      }
      .form-body {
        margin-top: 2rem;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 2rem;
        @media screen and (max-width: 900px) {
          grid-template-columns: 1fr;
        }
        .form-group {
          width: 100%;
          padding: 0 20px;
          margin-bottom: 1rem;
          label {
            font-size: 1rem;
            color: #000;
            font-weight: 600;
            margin-top: 0.5rem;
            display: block;
          }
          input,
          textarea {
            width: 100%;
            padding: 1rem;
            border: 1px solid #ccc;
            border-radius: 5px;
            font-size: 1rem;
            color: #000;
            font-weight: 400;
            transition: border 0.4s ease-in-out;
            &:focus {
              border: 1px solid $primary_color;
            }
          }
          &.group-3 {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 1rem;
            @media screen and (max-width: 900px) {
              grid-template-columns: 1fr;
            }
          }
          .input-group {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
          }
          button {
            padding: 1rem 1rem;
            border: none;
            border-radius: 5px;
            background-color: $primary_color;
            color: #fff;
            font-size: 1rem;
            font-weight: 600;
            cursor: pointer;
            transition: background-color 0.4s ease-in-out;
            width: 100%;
            margin-top: 1rem;
            &:hover {
              background-color: darken($primary_color, 10%);
            }
          }
        }
      }
    }
  }
}
</style>
