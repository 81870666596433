import { API_BASE_URL } from "../variables";

const GET_HOTELS_URL = API_BASE_URL + "/admin/hotels/getHotels";
const ADD_HOTEL_URL = API_BASE_URL + "/admin/hotels/addHotel";
const UPDATE_HOTEL_URL = API_BASE_URL + "/admin/hotels/updateHotel";
const DELETE_HOTEL_URL = API_BASE_URL + "/admin/hotels/deleteHotel";
const GET_HOTEL_BY_ID_URL = API_BASE_URL + "/admin/hotels/getHotelById";
const GET_FILTERED_HOTELS_URL =
  API_BASE_URL + "/admin/hotels/getFilteredHotels";
const GET_FEATURED_ITEMS_URL = API_BASE_URL + "/admin/getFeaturedItems";
const SEND_HOTEL_ORDER_URL = API_BASE_URL + "/admin/hotels/orders/sendOrder";

import axios from "axios";

const state = {};
const mutations = {};
const getters = {};
const actions = {
  ["GET_HOTELS"]: (state, payload) => {
    return axios({
      url: GET_HOTELS_URL + `?page=${payload.page}`,
      method: "POST",
      data: payload,
    });
  },
  ["ADD_HOTEL"]: (state, payload) => {
    return axios({
      url: ADD_HOTEL_URL,
      method: "POST",
      data: payload,
    });
  },
  ["UPDATE_HOTEL"]: (state, payload) => {
    return axios({
      url: UPDATE_HOTEL_URL,
      method: "POST",
      data: payload,
    });
  },
  ["DELETE_HOTEL"]: (state, payload) => {
    return axios({
      url: DELETE_HOTEL_URL,
      method: "POST",
      data: payload,
    });
  },
  ["GET_HOTEL_BY_ID"]: (state, payload) => {
    return axios({
      url: GET_HOTEL_BY_ID_URL,
      method: "POST",
      data: payload,
    });
  },
  ["GET_FILTERED_HOTELS"]: (state, payload) => {
    return axios({
      url: GET_FILTERED_HOTELS_URL,
      method: "POST",
      data: payload,
    });
  },
  ["GET_FEATURED_ITEMS"]: (state, payload) => {
    return axios({
      url: GET_FEATURED_ITEMS_URL,
      method: "POST",
      data: payload,
    });
  },
  ["SEND_HOTEL_ORDER"]: (state, payload) => {
    return axios({
      url: SEND_HOTEL_ORDER_URL,
      method: "POST",
      data: payload,
    });
  },
};

export default {
  mutations,
  actions,
  getters,
  state,
};
