<template>
  <div class="home">
    <clientHeader />
    <div class="all-content" v-if="activePackage">
      <div class="intro">
        <div class="intro-content">
          <div class="intro-body">
            <h2
              data-aos="fade-up"
              data-aos-duration="1000"
              class="intro-header"
            >
              {{ activePackage.title }}
            </h2>
            <p
              class="txt-shad-sm intro-desc"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Home / Tours / <strong>{{ activePackage.title }}</strong>
            </p>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="tour-details">
          <div class="left-tour-content">
            <div class="headers">
              <div class="left-headers">
                <h2>
                  {{ activePackage.title }}
                </h2>
                <p>
                  <i class="fas fa-map-marker-alt"></i>
                  <strong>{{ activePackage.location }}</strong>
                </p>
              </div>
              <div class="rigth-headers">
                <label for="">Starts From</label>
                <p class="price" v-if="activePackage.price">
                  <strong>{{ activePackage.price[0].value }}</strong>
                </p>
              </div>
            </div>
            <div class="icons">
              <div class="icon-item">
                <i class="fas fa-clock"></i>
                <div class="text-section">
                  <h4>Duration</h4>
                  <p>{{ activePackage.duration }}</p>
                </div>
              </div>
              <div class="icon-item">
                <i class="fa-solid fa-layer-group"></i>
                <div class="text-section">
                  <h4>Tour Type</h4>
                  <p>
                    <span
                      v-for="(category, index) in activePackage.category"
                      :key="category"
                    >
                      {{ category }}
                      {{ index < activePackage.category.length - 1 ? "," : "" }}
                    </span>
                  </p>
                </div>
              </div>
              <div class="icon-item">
                <i class="fas fa-users"></i>
                <div class="text-section">
                  <h4>Group Size</h4>
                  <p>2 - 10</p>
                </div>
              </div>
            </div>
            <div class="image-section">
              <div class="main-img">
                <img :src="`${$uploads(activePackage.image)}`" alt="" />
                <div class="other-imgs">
                  <carousel
                    :items-to-show="6.5"
                    :items-to-scroll="3.5"
                    :wrap-around="true"
                  >
                    <CouSlide
                      v-for="slide in activePackage.images"
                      :key="slide"
                    >
                      <img
                        :src="`${$uploads(slide)}`"
                        alt=""
                        @click="activePackage.image = slide"
                      />
                    </CouSlide>

                    <template #addons>
                      <navigation />
                    </template>
                  </carousel>
                </div>
              </div>
            </div>
            <div class="detail-item overview">
              <h3>Overview</h3>
              <p v-html="activePackage.description"></p>
            </div>
            <div class="detail-item Itinerary">
              <h3>Itinerary</h3>
              <div class="days-collapsible">
                <div
                  class="day-item"
                  v-for="(day, index) in activePackage.days"
                  :key="index"
                >
                  <div class="day-header">
                    <h4>Day {{ index + 1 }}:</h4>
                    <p>{{ day.title }}</p>
                  </div>
                  <div class="day-body">
                    <p v-html="day.description"></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="detail-item Included">
              <h3>Included / Excluded</h3>
              <div class="included-excluded">
                <div class="included">
                  <h4>Included</h4>
                  <ul>
                    <li v-for="item in activePackage.inclusives" :key="item">
                      {{ item }}
                    </li>
                  </ul>
                </div>
                <div class="excluded">
                  <h4>Excluded</h4>
                  <ul>
                    <li v-for="item in activePackage.exclusives" :key="item">
                      {{ item }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="detail-item map">
              <h3>Map</h3>
              <div class="map-section">
                <iframe
                  :src="`https://www.google.com/maps?q='${activePackage.location}'&output=embed`"
                ></iframe>
              </div>
            </div>
          </div>
          <div class="right-tour-content">
            <div class="headers">
              <div class="left-headers">
                <h2>Book Tour</h2>
                <p>
                  Fill in the form below to book this tour. Our team will get in
                  touch with you as soon as possible.
                </p>
              </div>
            </div>
            <div class="book-form">
              <form action="">
                <div class="form-group">
                  <label for="name">Name</label>
                  <input
                    type="text"
                    placeholder="Enter your name"
                    v-model="payload.names"
                  />
                </div>
                <div class="form-group">
                  <label for="email">Email</label>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    v-model="payload.email"
                  />
                </div>
                <div class="form-group">
                  <label for="phone">Phone</label>
                  <input
                    type="text"
                    placeholder="Enter your phone number"
                    v-model="payload.phone"
                  />
                </div>
                <div class="form-group">
                  <label for="phone">Number of People</label>
                  <input
                    type="number"
                    placeholder="Enter number of people"
                    v-model="payload.people"
                  />
                </div>
                <div class="form-group">
                  <label for="date">Start Date</label>
                  <input
                    type="date"
                    placeholder="Enter date"
                    v-model="payload.date"
                  />
                </div>
                <div class="form-group">
                  <label for="message">Additional Info</label>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    placeholder="Enter your message"
                    v-model="payload.message"
                  ></textarea>
                </div>
                <button type="submit" class="mybtn" @click.prevent="sendOrder">
                  Book Now
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <clientFooter />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      color: "#000",
      packages: [
        {
          id: 1,
          image: "rwanda2.jpg",
          location: "Volcanoes National Park",
          attraction: {
            name: "Rwanda",
            country: "Rwanda",
          },
          price: [
            {
              value: "$1000",
            },
          ],
          duration: "5 Days / 6 Nights",
          title: " A 5 Days Rwanda Gorilla Trekking Safari",
          type: "Group Tour",
        },
        {
          id: 2,
          image: "tanzania.jpg",
          attraction: {
            name: "Tanzania",
            country: "Tanzania",
          },
          price: [
            {
              value: "$2000",
            },
          ],
          duration: "7 Days / 8 Nights",
          title: "A 7 Days Tanzania Safari",
        },
        {
          id: 3,
          image: "uganda.jpg",
          attraction: {
            name: "Uganda",
            country: "Uganda",
          },
          price: [
            {
              value: "$3000",
            },
          ],
          duration: "10 Days / 11 Nights",
          title: "A 10 Days Adventure in Uganda",
        },
        {
          id: 4,
          image: "kigali.jpg",
          attraction: {
            name: "Kigali",
            country: "Rwanda",
          },
          price: [
            {
              value: "$4000",
            },
          ],
          duration: "3 Days / 4 Nights",
          title: "A 3 Days Kigali City Tour",
        },
        {
          id: 5,
          image: "kenya.jpg",
          attraction: {
            name: "Kenya",
            country: "Kenya",
          },
          price: [
            {
              value: "$5000",
            },
          ],
          duration: "6 Days / 7 Nights",
          title: "A 6 Days Kenya Safari",
        },
        {
          id: 6,
          image: "zanzibar.jpg",
          attraction: {
            name: "Zanzibar",
            country: "Tanzania",
          },
          price: [
            {
              value: "$6000",
            },
          ],
          duration: "4 Days / 5 Nights",
          title: "A 4 Days Zanzibar Beach Holiday",
        },
        {
          id: 1,
          image: "rwanda.jpg",
          attraction: {
            name: "Rwanda",
            country: "Rwanda",
          },
          price: [
            {
              value: "$1000",
            },
          ],
          duration: "5 Days / 6 Nights",
          title: " A 5 Days Rwanda Gorilla Trekking Safari",
        },
        {
          id: 2,
          image: "tanzania.jpg",
          attraction: {
            name: "Tanzania",
            country: "Tanzania",
          },
          price: [
            {
              value: "$2000",
            },
          ],
          duration: "7 Days / 8 Nights",
          title: "A 7 Days Tanzania Safari",
        },
        {
          id: 3,
          image: "uganda.jpg",
          attraction: {
            name: "Uganda",
            country: "Uganda",
          },
          price: [
            {
              value: "$3000",
            },
          ],
          duration: "10 Days / 11 Nights",
          title: "A 10 Days Adventure in Uganda",
        },
        {
          id: 4,
          image: "kigali.jpg",
          attraction: {
            name: "Kigali",
            country: "Rwanda",
          },
          price: [
            {
              value: "$4000",
            },
          ],
          duration: "3 Days / 4 Nights",
          title: "A 3 Days Kigali City Tour",
        },
        {
          id: 5,
          image: "kenya.jpg",
          attraction: {
            name: "Kenya",
            country: "Kenya",
          },
          price: [
            {
              value: "$5000",
            },
          ],
          duration: "6 Days / 7 Nights",
          title: "A 6 Days Kenya Safari",
        },
        {
          id: 6,
          image: "zanzibar.jpg",
          attraction: {
            name: "Zanzibar",
            country: "Tanzania",
          },
          price: [
            {
              value: "$6000",
            },
          ],
          duration: "4 Days / 5 Nights",
          title: "A 4 Days Zanzibar Beach Holiday",
        },
      ],
      trips: [],
      partners: [],
      currentSlide: 0,
      activetab: 1,
      activePackage: {},
      activeId: null,
      payload: {
        names: "",
        email: "",
        phone: "",
        people: "",
        message: "",
        date: "",
        package_id: "",
      },
    };
  },
  methods: {
    prev() {
      if (this.currentSlide > 0) {
        this.currentSlide--;
      } else {
        this.currentSlide = this.slides.length - 1;
      }
    },
    next() {
      // if (this.currentSlide < this.slides.length - 1) {
      this.currentSlide++;
      // } else {
      //   this.currentSlide = 0;
      // }
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_SINGLE_PACKAGE", {
          id: this.activeId,
        })
        .then((response) => {
          this.activePackage = response.data.package;
          this.packages = response.data.related;
          this.$stopLoader();
        });
    },
    sendOrder() {
      this.payload.package_id = this.activeId;
      this.$startLoader();
      this.$store
        .dispatch("BOOK_PACKAGE", { ...this.payload })
        .then((response) => {
          if (response.data.status == "ok") {
            this.$notify({
              group: "status",
              type: "success",
              title: "Success",
              text: "Your order has been sent successfully, you will receive a confirmation email shortly",
            });
            this.$stopLoader();
            this.reserveModel = false;
          }
        })
        .catch((error) => {
          this.$notify({
            group: "status",
            type: "error",
            title: "Error",
            text: "An error occurred, please try again",
          });
          this.$stopLoader();
        });
    },
  },
  mounted() {
    let vm = this;
    this.activeId = this.$route.params.id;
    this.getItems();
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/main.scss";
.home {
  width: 99.1vw;
  overflow: hidden;
  @media screen and (max-width: 900px) {
    width: 100vw;
  }
  .all-content {
    .tour-details {
      width: 100%;
      display: grid;
      grid-template-columns: 70% 30%;
      padding: 4rem 0;
      gap: 2rem;
      @media screen and (max-width: 900px) {
        grid-template-columns: 100%;
      }
      .left-tour-content {
        .headers {
          display: flex;
          justify-content: space-between;
          .left-headers {
            h2 {
              font-size: 1.8rem;
              color: #000;
              font-weight: 600;
            }
            p {
              font-size: 1rem;
              color: #000000dc;
              display: flex;
              align-items: center;
              gap: 0.2rem;
              font-weight: 300;
            }
          }
          .rigth-headers {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            label {
              font-size: 1rem;
              color: #000;
              font-weight: 400;
              text-align: right;
            }
            p {
              font-size: 1.5rem;
              color: $primary_color;
              font-weight: 500;
              text-align: right;
            }
          }
        }
        .icons {
          display: flex;
          justify-content: space-between;
          gap: 1rem;
          border-bottom: 1px #00000021 solid;
          border-top: 1px #00000021 solid;
          .icon-item {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem;
            border-radius: 5px;
            i {
              font-size: 1.75rem;
              color: #000;
            }
            .text-section {
              margin-left: 1rem;
              h4 {
                font-size: 1rem;
                color: #000;
                font-weight: 600;
              }
              p {
                font-size: 0.8rem;
                color: #00000096;
              }
            }
          }
        }
        .image-section {
          margin-top: 2rem;
          .main-img {
            position: relative;
            img {
              width: 100%;
              height: 400px;
              object-fit: cover;
              border-radius: 15px;
            }
            .other-imgs {
              width: 100%;
              img {
                height: 100px;
                object-fit: cover;
                border-radius: 10px;
                padding: 5px;
                cursor: pointer;
              }
            }
          }
        }
        .detail-item {
          margin-top: 2rem;
          padding: 20px;
          border-radius: 15px;
          box-shadow: 0 0 10px 0 #00000021;
          h3 {
            font-size: 1.5rem;
            color: #000;
            font-weight: 600;
            border-bottom: 1px $secondary_color solid;
            width: fit-content;
            line-height: 1.5;
          }
          ul {
            list-style: none;
            li {
              font-size: 1rem;
              color: #000;
              padding: 0.35rem 0;
              position: relative;
            }
          }
        }
        .overview {
          p ::v-deep p {
            line-height: 1.5;
          }
          p ::v-deep ul,
          ol {
            margin-left: 1rem;
          }
          ul {
            li {
              &:hover {
                &:before {
                  transform: scaleX(1);
                }
              }
            }
          }
        }
        .Itinerary {
          .days-collapsible {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            .day-item {
              border-bottom: 1px #00000021 solid;
              margin-bottom: 10px;
              &:last-child {
                border-bottom: none;
                margin-bottom: 0px;
              }
              .day-header {
                display: flex;
                gap: 5px;
                h4 {
                  font-size: 1.2rem;
                  color: #000;
                  font-weight: 600;
                }
                p {
                  font-size: 1.2rem;
                  color: #000;
                  font-weight: 600;
                }
              }
              .day-body {
                p {
                  font-size: 0.95rem;
                  color: #000000d1;
                  font-weight: 400;
                  line-height: 1.5;
                  b {
                    font-weight: 800;
                  }
                }
              }
            }
          }
        }
        .Included {
          .included-excluded {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1rem;
            .included,
            .excluded {
              h4 {
                font-size: 1.2rem;
                color: #000;
                font-weight: 600;
              }
              ul {
                list-style: none;
                li {
                  font-size: 1rem;
                  color: #00000096;
                  font-weight: 400;
                  padding: 0.5rem 0;
                  position: relative;
                  &:before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    transform: scaleX(0);
                    height: 1px;
                    bottom: 0;
                    left: 0;
                    background-color: $primary_color;
                    transform-origin: bottom right;
                    transition: transform 0.4s ease-in-out;
                  }
                }
              }
            }
          }
        }
        .map {
          .map-section {
            iframe {
              width: 100%;
              height: 400px;
              border: none;
              border-radius: 15px;
            }
          }
        }
      }
      .right-tour-content {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        box-shadow: 0 0 10px 0 #00000021;
        border-radius: 15px;
        padding: 20px;
        align-self: flex-start;
        .headers {
          .left-headers {
            h2 {
              font-size: 1.8rem;
              color: #000;
              font-weight: 600;
            }
            p {
              font-size: 1rem;
              color: #000000dc;
              font-weight: 300;
            }
          }
        }
        .book-form {
          form {
            .form-group {
              margin-bottom: 1rem;
              label {
                font-size: 1rem;
                color: #000;
                font-weight: 600;
              }
              input,
              textarea {
                width: 100%;
                padding: 0.5rem;
                border: 1px #00000021 solid;
                border-radius: 5px;
                font-size: 1rem;
                color: #000;
                font-weight: 300;
                margin-top: 0.5rem;
              }
              textarea {
                height: 100px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
