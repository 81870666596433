<template>
  <div class="Sidebar">
    <div class="nav">
      <div class="brand">
        <img src="/img/plogot.png" alt="" srcset="" />
      </div>
      <ul class="nav-items">
        <!-- <li>
          <router-link to="/admin" class="nav-item"
            ><i class="fa fa-tachometer-alt"></i
            >&nbsp;&nbsp;Dashboard</router-link
          >
        </li> -->
        <li>
          <router-link :to="{ name: 'AdminDestinations' }" class="nav-item"
            ><i class="fa-solid fa-mountain-city"></i
            >&nbsp;&nbsp;Destinations</router-link
          >
        </li>
        <li>
          <router-link to="/admin/packages" class="nav-item"
            ><i class="fa-solid fa-hippo"></i>&nbsp;&nbsp;Packages</router-link
          >
        </li>
        <!-- <li>
          <router-link to="/admin/trips" class="nav-item"
            ><i class="fas fa-bus"></i>&nbsp;&nbsp;Trips</router-link
          >
        </li> -->
        <li>
          <router-link :to="{ name: 'AdminHotels' }" class="nav-item"
            ><i class="fa fa-bed"></i>&nbsp;&nbsp;Hotels</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'AdminStays' }" class="nav-item"
            ><i class="fa fa-building"></i>&nbsp;&nbsp;Stays</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'AdminBlog' }" class="nav-item"
            ><i class="fa-solid fa-blog"></i>&nbsp;&nbsp;Blog</router-link
          >
        </li>
        <li>
          <router-link to="/admin/slides" class="nav-item"
            ><i class="fa-solid fa-images"></i>&nbsp;&nbsp;Slides</router-link
          >
        </li>
        <li>
          <router-link to="/admin/partners" class="nav-item"
            ><i class="fa fa-handshake"></i>&nbsp;&nbsp;Partners</router-link
          >
        </li>
        <li>
          <router-link to="/admin/users" class="nav-item"
            ><i class="fa fa-users"></i>&nbsp;&nbsp;Users</router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  method: {},
  mounted() {
    if (this.$loggedAdmin()) {
    } else {
      this.$adminlogout();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/main.scss";
.Sidebar {
  .nav {
    width: 200px;
    background: rgb(3, 1, 0);
    background: linear-gradient(
      130deg,
      rgba(3, 1, 0, 1) 0%,
      rgba(37, 17, 0, 1) 47%,
      rgb(27, 13, 2) 100%
    );
    height: 100vh;
    overflow: hidden;
    box-shadow: 0px 5px 20px 10px #f0f0f0;
    @media only screen and (max-width: 600px) {
      width: 100px;
    }
    .brand {
      position: relative;
      padding: 1rem 1.5rem;
      background: #030200;
      display: flex;
      justify-content: center;
      // inner shadow
      box-shadow: inset 0px 0px 1000px 0px #0000003d;
      img {
        width: 80%;
      }
    }
    .nav-items {
      display: flex;
      flex-direction: column;
      li {
        display: block;
        border-bottom: 1px #eeeeee3a solid;
        .nav-item {
          background: none;
          border: none;
          padding: 0.85rem 1rem;
          color: #eee;
          display: grid;
          grid-template-columns: 18px 1fr;
          font-size: 0.85rem;
          width: 100%;
          text-align: left;
          cursor: pointer;
          transition: all 0.5s;
          &.router-link-exact-active,
          &:hover {
            background: rgba(255, 145, 0, 0.349);
            color: #eee;
          }
        }
      }
    }
  }
}
</style>
