<template>
  <div class="home">
    <clientHeader />
    <div class="all-content">
      <div class="intro">
        <div class="intro-content">
          <div class="intro-body">
            <h2
              data-aos="fade-up"
              data-aos-duration="1000"
              class="intro-header"
            >
              Destinations
            </h2>
            <p
              class="txt-shad-sm intro-desc"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Home / <strong>Destinations</strong>
            </p>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="destinations">
          <div class="search-input">
            <input type="text" placeholder="Search Destinations" />
            <button>
              <i class="fas fa-search"></i>
            </button>
          </div>
          <div class="destinations-sections">
            <div
              class="destination-item"
              v-for="destination in destinations"
              :key="destination.id"
            >
              <div class="img-section">
                <img :src="$img(destination.image)" alt="" />
              </div>
              <div class="text-section on-hover">
                <label for="Destination">{{ destination.name }}</label>
                <p>{{ destination.packages }} Packages</p>
              </div>
              <div class="display-text">
                <p>{{ destination.name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <clientFooter />
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      isLoading: false,
      color: "#072e4d",
      fullPage: true,
      packages: [
        {
          id: 1,
          image: "rwanda.jpg",
          attraction: {
            name: "Rwanda",
            country: "Rwanda",
          },
          price: [
            {
              value: "$1000",
            },
          ],
          duration: "5 Days / 6 Nights",
          title: " A 5 Days Rwanda Gorilla Trekking Safari",
        },
        {
          id: 2,
          image: "tanzania.jpg",
          attraction: {
            name: "Tanzania",
            country: "Tanzania",
          },
          price: [
            {
              value: "$2000",
            },
          ],
          duration: "7 Days / 8 Nights",
          title: "A 7 Days Tanzania Safari",
        },
        {
          id: 3,
          image: "uganda.jpg",
          attraction: {
            name: "Uganda",
            country: "Uganda",
          },
          price: [
            {
              value: "$3000",
            },
          ],
          duration: "10 Days / 11 Nights",
          title: "A 10 Days Adventure in Uganda",
        },
      ],
      trips: [],
      partners: [],
      currentSlide: 0,
      destinations: [
        {
          id: 1,
          name: "Tanzania",
          image: "zanzibar.jpg",
          packages: 10,
        },
        {
          id: 3,
          name: "Uganda",
          image: "uganda.jpg",
          packages: 36,
        },
        {
          id: 4,
          name: "Rwanda",
          image: "kigali.jpg",
          packages: 20,
        },
        {
          id: 5,
          name: "Kenya",
          image: "kenya.jpg",
          packages: 35,
        },
      ],
      activetab: 1,
    };
  },
  methods: {
    prev() {
      if (this.currentSlide > 0) {
        this.currentSlide--;
      } else {
        this.currentSlide = this.slides.length - 1;
      }
    },
    next() {
      // if (this.currentSlide < this.slides.length - 1) {
      this.currentSlide++;
      // } else {
      //   this.currentSlide = 0;
      // }
    },
    getPackages() {
      this.$startLoader();
      this.$store.dispatch("GET_POPULAR_PACKAGES").then((response) => {
        // this.packages = response.data["packages"];
        // this.packages.forEach((item) => {
        //   item.price = JSON.parse(item.price);
        // });

        // this.trips = response.data["trips"];
        // this.trips.forEach((item) => {
        //   item.price = JSON.parse(item.price);
        // });
        this.$stopLoader();
      });
    },

    getPartners() {
      this.$store.dispatch("GET_PARTNER").then((response) => {
        this.partners = response.data;
      });
    },
    getSlides() {
      // this.$store.dispatch("GET_SLIDE").then((response) => {
      //   this.slides = response.data;
      // });
    },
  },
  mounted() {
    let vm = this;
    // this.getPackages();
    // this.getPartners();
    // this.getSlides();
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/main.scss";
.home {
  width: 99.1vw;
  overflow: hidden;
  @media screen and (max-width: 900px) {
    width: 100vw;
  }
  .destinations {
    padding: 8rem 0;
    width: 100%;
    background: url("@/assets/images/bgs-01.png") no-repeat;
    background-size: cover;
    .search-input {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 2rem 0;
      input {
        width: 50%;
        padding: 1rem;
        border: none;
        border-radius: 5px 0px 0px 5px;
        outline: none;
        font-size: 1rem;
        border: 1px #d8d8d8 solid;
        @media screen and (max-width: 900px) {
          width: 80%;
        }
      }
      button {
        background: $primary_color;
        border: none;
        border-radius: 0px 5px 5px 0px;
        padding: 1rem 2rem;
        cursor: pointer;
        i {
          font-size: 1rem;
          color: #fff;
        }
      }
    }
    .destinations-sections {
      gap: 1rem;
      margin: 2rem 0;
      position: relative;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      .destination-item {
        background: #fff;
        border-radius: 1.5rem;
        box-shadow: 0px 0px 10px 3px #4d4d4d2a;
        overflow: hidden;
        transition: all 0.4s ease-in-out;
        position: relative;
        cursor: pointer;
        .text-section {
          position: absolute;
          bottom: -400px;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 1rem;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          label {
            font-size: 1.5rem;
            font-weight: 600;
            color: white;
            display: flex;
            align-items: center;
            i {
              font-size: 1.5rem;
            }
          }
          p {
            font-size: 0.6rem;
            font-weight: 400;
            color: white;
            background: url("@/assets/images/bgo.svg") no-repeat;
            background-size: contain;
            padding: 1rem 2rem;
          }
        }
        .img-section {
          position: relative;
          height: 300px;
          width: 100%;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 1.5rem;
            @media screen and (max-width: 1300px) {
              height: 200px;
            }
            @media screen and (max-width: 1100px) {
              height: 250px;
            }
            @media screen and (max-width: 700px) {
              height: 200px;
            }
          }
        }
        .display-text {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          background: rgba(0, 0, 0, 0.233);
          p {
            font-size: 1.5rem;
            font-weight: 500;
            color: #fff;
            padding: 0rem 2rem;
          }
        }
        &:hover,
        &:focus {
          transform: scale(1, 1);
          z-index: 1;
          .text-section {
            transition: all 0.4s ease-in-out;
            bottom: 0px;
          }
          .display-text {
            display: none;
          }
        }
      }
      .view-all {
        background: #fff;
        border-radius: 1.5rem;
        box-shadow: 0px 0px 10px 3px #4d4d4d2a;
        overflow: hidden;
        transition: all 0.4s ease-in-out;
        background: url("@/assets/images/bgs-03.png") no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover,
        &:focus {
          transform: scale(1, 1);
          z-index: 1;
        }
        .img-section {
          position: relative;
          img {
            height: 250px;
            width: 100%;
            object-fit: cover;
            border-radius: 1.5rem;
            @media screen and (max-width: 1300px) {
              height: 200px;
            }
            @media screen and (max-width: 1100px) {
              height: 250px;
            }
            @media screen and (max-width: 700px) {
              height: 200px;
            }
          }
        }
        .display-text {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          p {
            font-size: 1.2rem;
            font-weight: 500;
            color: #1d1d1d;
          }
        }
      }
    }
  }

  .packages {
    position: relative;
    background: url("@/assets/images/bgs-02.png") no-repeat;
    background-size: cover;
    .header {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      @media screen and (max-width: 600px) {
        align-items: flex-start;
      }
      .desc {
        color: #333333;
        font-weight: 400;
        font-size: 1.1rem;
        @media screen and (max-width: 1200px) {
          font-size: 1rem;
        }
        @media screen and (max-width: 900px) {
          font-size: 0.8rem;
        }
      }
    }
    .packages-body {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 2%;
      grid-row-gap: 2rem;
      margin: 2rem 0;
      flex-wrap: wrap;
      @media screen and (max-width: 1100px) {
        grid-template-columns: 48% 48%;
      }
      @media screen and (max-width: 600px) {
        grid-template-columns: 100%;
      }
      .package-item {
        background: #fff;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 3px #4d4d4d11;
        // overflow: hidden;
        transition: all 0.4s ease-in-out;
        &:hover,
        &:focus {
          z-index: 1;
        }
        .content {
          display: flex;
          flex-direction: column;
          position: relative;
          .img-section {
            position: relative;
            height: 250px;
            width: 100%;
            border-radius: 10px;
            padding: 1rem;
            img {
              height: 100%;
              width: 100%;
              border-radius: 10px;
              object-fit: cover;
              @media screen and (max-width: 1300px) {
                height: 200px;
              }
              @media screen and (max-width: 1100px) {
                height: 250px;
              }
              @media screen and (max-width: 700px) {
                height: 200px;
              }
            }
            .specs {
              background-color: #fff;
              border-radius: 100px;
              padding: 0.4rem 1rem;
              position: absolute;
              bottom: -10px;
              width: 80%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              color: #2b2b2b;
              font-size: 0.8rem;
              box-shadow: 0px 0px 10px 3px #0000003b;
              .region {
                display: flex;
                align-items: center;
                column-gap: 0.25rem;
              }
            }
          }
          .text-section {
            padding: 0 1rem;
            padding-top: 2rem;
            @media screen and (max-width: 1300px) {
              padding: 0 0.75rem;
              padding-top: 1.5rem;
            }
            .package-title {
              color: #1d1d1d;
              font-weight: 500;
              font-size: 1.2rem;
              @media screen and (max-width: 1300px) {
                font-size: 1rem;
              }
            }
          }
          hr {
            align-self: center;
            width: 90%;
            border: none;
            border-top: 1px #eee solid;
            margin: 1rem 0;
          }
          .content-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 1rem;
            .money {
              color: $primary_color;
              font-size: 1.2rem;
              font-weight: 500;
              @media screen and (max-width: 1300px) {
                font-size: 1.25rem;
              }
              span {
                color: #000000;
                font-size: 0.75rem;
                font-weight: 400;
              }
              p {
                font-size: 1.6rem;
                font-weight: 600;
              }
            }
            .book {
              .book-btn {
                color: #fff;
                font-size: 0.8rem;
                font-weight: 600;
                padding: 0.75rem 1.5rem;
                background: $primary_color;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                transition: all 0.4s ease-in-out;
                display: flex;
                align-items: center;
                column-gap: 0.5rem;
                i {
                  font-size: 1rem;
                }
              }
            }
          }
        }
      }
    }
    .packages-footer {
      padding: 2rem 0;
    }
  }
}
</style>
