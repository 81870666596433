


import {
  API_BASE_URL
} from "../variables";

const GET_UNITS_URL = API_BASE_URL + "/admin/stayUnits/getStayUnits";
const ADD_UNIT_URL = API_BASE_URL + "/admin/stayUnits/addStayUnit";
const UPDATE_UNIT_URL = API_BASE_URL + "/admin/stayUnits/updateStayUnit";
const DELETE_UNIT_URL = API_BASE_URL + "/admin/stayUnits/deleteStayUnit";
const GET_UNIT_BY_ID_URL = API_BASE_URL + "/admin/stayUnits/getStayUnitById";
const GET_UNITS_BY_STAY_ID_URL = API_BASE_URL + "/admin/stayUnits/getStayUnitByStayId";


import axios from "axios";

const state = {};
const mutations = {};
const getters = {};
const actions = {
  ["GET_UNITS"]: (state, payload) => {
    return axios({
      url: GET_UNITS_URL + `?page=${payload.page}`,
      method: "POST",
      data: payload,
    });
  },
  ["ADD_UNIT"]: (state, payload) => {
    return axios({
      url: ADD_UNIT_URL,
      method: "POST",
      data: payload,
    });
  },
  ["UPDATE_UNIT"]: (state, payload) => {
    return axios({
      url: UPDATE_UNIT_URL,
      method: "POST",
      data: payload,
    });
  },
  ["DELETE_UNIT"]: (state, payload) => {
    return axios({
      url: DELETE_UNIT_URL,
      method: "POST",
      data: payload,
    });
  },
  ["GET_UNIT_BY_ID"]: (state, payload) => {
    return axios({
      url: GET_UNIT_BY_ID_URL,
      method: "POST",
      data: payload,
    });
  },
  ["GET_UNITS_BY_STAY_ID"]: (state, payload) => {
    return axios({
      url: GET_UNITS_BY_STAY_ID_URL,
      method: "POST",
      data: payload,
    });
  },
};

export default {
  mutations,
  actions,
  getters,
  state,
};
